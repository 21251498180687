import React, {useState, useRef, Component, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {ConnectionContext} from './ConnectionSystem';

const ModalVoirBoutique = ({panierId, boutik, nomProd, imagePrincipale, image1, image2, image3, image4, coutProd, infoProd, serieProd, boutikType, closeBoutik}) => {
	const {hermannlepatrioteClientlogin, hermannlepatrioteClientlogout, hermannlepatrioteClientconnecte, formatDateFr} = useContext(ConnectionContext);
	const [boutikProduit, setBoutikproduit] = useState(false);
	const [visuelProduit, setVisuelproduit] = useState("`${imagePrincipale}`");
	const [messageSuccess, setMessageSuccess] = useState(true);
	const [hlpPanierTemp, setHlppaniertemp] = useState(panierId);
	const [produitTaille, setProduittaille] = useState('');
	//
	useEffect(() => {
		if (document.getElementById('visuel-settings-default').classList.contains('hideitem')) {
			document.getElementById('visuel-settings-default').classList.remove('hideitem');
			document.getElementById('visuel-settings').classList.add('hideitem');
		}
		if (!boutik) {
			setVisuelproduit("");
		}
	}, [boutik]);
	const settingsProduit3 = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		vertical: true,
		verticalSwiping: true,
		arrows: false,
		autoplay: true,
		adaptativeHeight: true,
    	speed: 1500,
    	autoplaySpeed: 6500,
    	pauseOnHover: true,
	};
	const insertProduitIntoCart = async(i, n, im, c) => {
		var achatDate = formatDateFr(new Date());
		var p = panierId;
		axios.post('https://api.hermannlepatriote.com/_010111SQL_/insertionachathorsligne', {p, i, n, im, c, achatDate})
		.then(response => {
			switch(response.data.erreur) {
				case 'ErreurAchatCheck1':
					return alert(response.data.message);
				case 'AchatError':
					return alert(response.data.message);
				case 'PanierError':
					return alert(response.data.message);
				case 'PanierAchatSuccess1':
					return ajoutBoutique();
				case 'PanierAchatSuccess2':
					return ajoutBoutique();
				default:
					return null;
			}
			return;
		})
		.catch(error => {
			console.error('Erreur achat article !', error);
		});
	};
	function getVisuelproduit(p) {
		setVisuelproduit(p);
		document.getElementById('visuel-settings-default').classList.add('hideitem');
		document.getElementById('visuel-settings').classList.remove('hideitem');
	}
	function achatBoutique(p, n, i, c) {
		insertProduitIntoCart(p, n, i, c);	
	}
	function ajoutBoutique() {
		document.getElementById('transition-effect').classList.remove('hidden');
		const timer = setTimeout(() => {
			document.getElementById('transition-effect').classList.add('hidden');
			setMessageSuccess(false);
		}, 3000);
		return () => clearTimeout(timer);
	}
	return (
		<div className={`${boutik ? 'modal-show' : 'modal'}`}>
			<div className="modal-boutik-content shadow-xl">
				<p className="text-2xl border-b border-gray-200 py-2 px-3">
					{nomProd}
					<span className="close-button" onClick={closeBoutik}>&times;</span>
				</p>
				<div className="flex flex-wrap lg:justify-between md:justify-center p-5">
					<div className="w-full flex flex-wrap md:justify-center" id="page-boutique-view1">
						<div className="lg:w-4/6 md:max-w-xl">
							<div className="w-full flex flex-wrap items-center">
								<div className="w-3/12 slider-container">
									<Slider {...settingsProduit3}>
										<div className="cursor-pointer my-1" onClick={() => getVisuelproduit(`${imagePrincipale}`)}>
											<img src={`${imagePrincipale}`} alt="" />
										</div>
										<div className={image1 ? 'cursor-pointer my-1' : 'hidden'} onClick={() => getVisuelproduit(`${image1}`)}>
											<img src={`${image1}`} alt="" />
										</div>
										<div className={image2 ? 'cursor-pointer my-1' : 'hidden'} onClick={() => getVisuelproduit(`${image2}`)}>
											<img src={`${image2}`} alt="" />
										</div>
										<div className={image3 ? 'cursor-pointer my-1' : 'hidden'} onClick={() => getVisuelproduit(`${image3}`)}>
											<img src={`${image3}`} alt="" />
										</div>
										<div className={image4 ? 'cursor-pointer my-1' : 'hidden'} onClick={() => getVisuelproduit(`${image4}`)}>
											<img src={`${image4}`} alt="" />
										</div>
									</Slider>
							    </div>
								<div className="w-9/12 flex justify-center px-2">
									<img className="h-auto" id="visuel-settings-default" src={`${imagePrincipale}`} alt="image" />
									<img className="h-auto hideitem" id="visuel-settings" src={visuelProduit} alt="visuel" />
								</div>
							</div>
						</div>
						<div className="lg:w-2/6 md:max-w-xl" id="page-boutique-div2">
							<span className="underline text-md">Description:</span>
							<div className="mt-5">
								<div dangerouslySetInnerHTML={{ __html: infoProd}} />
							</div>
							<div className={boutikType == 1 ? 'w-full flex flex-wrap my-5' : 'hidden'}>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2 cursor-pointer">S</span>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2  cursor-pointer">M</span>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2 cursor-pointer">L</span>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2 cursor-pointer">XL</span>
							</div>	
							<div className="w-full flex flex-wrap my-5">
								<span className="text-3xl font-bold">$ {coutProd}</span>
							</div>	
							<div className="w-full flex flex-wrap">
								<button onClick={() => achatBoutique(`${serieProd}`,`${nomProd}`,`${imagePrincipale}`,`${coutProd}`)}
								 className="w-3/5 text-xl flex flex-wrap items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
						            <svg className="w-6 h-6 text-white mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
						            		<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6"/>
						            	</svg>
						            Acheter
						        </button>
							</div>	
							<div className="w-full flex flex-wrap mt-5 bg-green-200 justify-center py-1 hidden" id="transition-effect">
								Ce produit est ajouté à votre panier !
							</div>												
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalVoirBoutique;
