import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {ConnectionContext} from './ConnectionSystem';

const CheminSecurise = ({component: Component}) => {
	const {hermannlepatrioteClientconnecte} = useContext(ConnectionContext);
	if (!hermannlepatrioteClientconnecte()) {
		// return <Navigate to="/blog" />;
	}

	return <Component />
}

export default CheminSecurise;
