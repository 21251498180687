import React from 'react';
import {Link} from 'react-router-dom';

function ErreurPage() {
    return (
        <section className="bg-white h-full flex flex-col justify-center">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-3 text-7xl tracking-tight font-bold lg:text-9xl text-purple-800">
                        404
                    </h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                        Erreur de navigation
                    </p>
                    <p className="mb-12 text-lg font-light text-gray-500">
                        Desolé, cette page que vous demandée n'existe pas !
                    </p>
                    <Link to="/" className="items-center duration-500 transition-all inline-flex text-white bg-blue-500 hover:bg-gray-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">
                        <svg className="w-8 h-8 text-white mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd"/>
                        </svg>
                        <span className="font-bold text-xl">
                            Revenir sur HermannLePatriote
                        </span>                        
                    </Link>
                </div>   
            </div>
        </section>
    );
}

export default ErreurPage;
