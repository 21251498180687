import React, {useState, useEffect} from 'react';
import axios from 'axios';

function FlashInfos() {
	const [afficherFlashinfo, setafficherFlashinfos] = useState('');
	const [flashinfoId, setFlashinfoid] = useState(0);
	let flashinfoText = ["ok merci", "deuxieme merci ", "respecter ta parole", "car tu es le boss des boss"];

	useEffect(() => {
		
	}, []);

	const envoiMail = async() => {
		let eml = "bley.candide@gmail.com";
		try {
			const resp = axios.post('https://gcxdpnertkjmwf.hermannlepatriote.com/mailer/contact.php', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				body: JSON.stringify({nomClient: "NOMCIE", emailClient: "bley.candide@gmail.com"}),
			});
			if (resp.erreur = 'successemail') {
				alert('ok cool !');
			} else {
				alert('Une erreur est survenue !');
			}
		} catch(error) {
			console.error('Erreur nouveau sujet:',error);
		}
	};

	return (
		<div className="w-5/6 px-2 flex flex-wrap">
			<p className="truncate font-serif">
				La boutique hermannlepatriote est ouverte maintenant !
			</p>
		</div>
	);

}

export default FlashInfos;
